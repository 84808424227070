<template>
  <form
    class="flex w-full flex-col items-start space-y-2 px-9.5"
    @submit.prevent="onSubmit"
  >
    <div class="w-full">
      <div class="mb-1 font-medium capitalize">{{ $t("firstName") }}</div>
      <UiInput
        v-model="form.first_name"
        class="flex-1"
        name="first_name"
        placeholder="John"
        data-e2e-placeholder="first-name"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium capitalize">{{ $t("lastName") }}</div>
      <UiInput
        v-model="form.last_name"
        class="flex-1"
        name="last_name"
        placeholder="Smith"
        data-e2e-placeholder="last-name"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium capitalize">{{ $t("emailAddress") }}</div>
      <UiInput
        v-model="form.email"
        class="flex-1"
        name="email"
        :placeholder="$t('enterYourEmailAddress')"
        data-e2e-placeholder="email"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium">{{ $t("password") }}</div>
      <UiPasswordField
        v-model="form.password"
        class="flex-1"
        name="password"
        placeholder="••••••••••••"
        autocomplete="new-password"
        data-e2e-placeholder="password"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium">{{ $t("repeatPassword") }}</div>
      <UiPasswordField
        v-model="form.password_confirmation"
        class="flex-1"
        name="password_confirmation"
        placeholder="••••••••••••"
        data-e2e-placeholder="repeat-password"
      />
    </div>
    <UiButton
      :loading="isSubmitting"
      type="submit"
      color="primary"
      class="mb-4 w-full"
      data-e2e-submit="sign-up"
    >
      {{ $t("signUp") }}
    </UiButton>
    <div class="font-medium text-primary">
      {{ $t("alreadyHaveAnAccount") }}
      <button
        class="underline"
        type="button"
        @click="$emit('changeForm', AuthDrawerEnum.LOGIN)"
      >
        {{ $t("logIn") }}
      </button>
    </div>
    <div v-if="generalStore.getFirebaseIntegration && $socialAuth.auth">
      <div class="my-5 w-full text-center font-light">
        {{ $t("orSignUp") }}
      </div>
      <SharedSocialAuth @success="successAuth" />
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { object, ref, string } from "yup";
import { SignUpForm } from "ecom-types";
import { FetchError } from "ofetch";

import { AuthDrawerEnum, AuthDrawerType } from "~/types/components.types";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const localePath = useLocalePathPolyfill();

const generalStore = useGeneralStore();
const generalInternalStore = useGeneralInternalStore();
defineEmits<{
  (e: "changeForm", type: AuthDrawerType): void;
}>();

const { openSystemDialog } = useSystemDialogs();
const { onSignUp } = useAuth();
const initialSignUpForm: SignUpForm = {
  first_name: "",
  last_name: "",
  password: "",
  password_confirmation: "",
  email: "",
};

const schemaSignUp = object().shape({
  email: string().email().required().label(t("emailAddress")),
  first_name: string().required().label(t("firstName")),
  last_name: string().required().label(t("lastName")),
  password: string()
    .matches(passwordRegexp, {
      message: t("passwordRequirements"),
    })
    .required()
    .label(t("password")),
  password_confirmation: string()
    .oneOf([ref("password"), ""], "Passwords must match")
    .required()
    .label(t("repeatPassword")),
});

const form = reactive<SignUpForm>(
  JSON.parse(JSON.stringify(initialSignUpForm)),
);

const {
  handleSubmit: handleSubmitSignUp,
  resetForm: resetFormSignUp,
  setErrors: setErrorsSignUp,
  isSubmitting,
} = useForm({
  initialValues: initialSignUpForm,
  validationSchema: schemaSignUp,
});

async function successAuth() {
  if (route.query.redirect) {
    await router.replace(localePath(route.query.redirect as string));
  } else {
    await router.replace(localePath("/profile"));
  }
}

const onSubmit = handleSubmitSignUp(async (values) => {
  try {
    await onSignUp(values);
    resetFormSignUp();
    openSystemDialog({
      type: "alert",
      title: `${t("success")}!`,
      text: `${t("confirmMessage")}.`,
      successHandler: async () => {
        generalInternalStore.isSignInDrawerOpen = false;
        await router.push(localePath("/"));
      },
    });
  } catch (error) {
    if (error instanceof FetchError) {
      const errors = responseErrorToObjectErrors(error?.data?.errors, form);
      if (!isEmpty(errors)) {
        setErrorsSignUp(errors);
      } else {
        openSystemDialog({
          type: "alert",
          title: `${t("error")}!`,
          text: error?.data?.errors[0].message,
        });
      }
    }
  }
});
</script>
